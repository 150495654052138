import { Routes } from 'constants/routes'
import { useSigninEffect } from 'hooks/useSigninEffect'
import { trackEvent } from 'monitoring/events'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { AWSFeatureFlagManager } from 'services/AWSFeatureFlagManager'
import { getStringParam } from 'utils/router/routerUtils'

import { useTranslation } from 'react-i18next'
import { useSession } from 'next-auth/react'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import PageLoader from 'components/loaders/PageLoader'

export const getServerSideProps: GetServerSideProps = async () => {
  const featureFlagsManager = new AWSFeatureFlagManager()
  const disableSigninCallbackUrl = await featureFlagsManager.getFeatureFlag(
    'disable_signin_callback_url'
  )

  return {
    props: {
      disableSigninCallbackUrl,
    },
  }
}

const SignIn = ({
  disableSigninCallbackUrl,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const session = useSession()
  const router = useRouter()
  const { t } = useTranslation('auth', { keyPrefix: 'signin' })
  const errorCode = getStringParam(router.query.error)
  const canRedirect = router.isReady && !errorCode

  useEffect(() => {
    if (session.status === 'loading') return

    // The user may be redirected back to this page if an error is encountered during the sign in process.
    // https://next-auth.js.org/configuration/pages#sign-in-page
    if (errorCode) {
      trackEvent('signin-error', {
        errorCode,
        sessionStatus: session.status,
        cognitoId: session.data?.whoAmI?.cognitoId,
      })
    }

    // If user authenticated, but manually enters or bookmarks 'signin' path in url, redirect to home page
    if (
      canRedirect &&
      session.status === 'authenticated' &&
      session.data.whoAmI
    ) {
      trackEvent('signin-page-authenticated-redirect', {
        cognitoId: session.data.whoAmI.cognitoId,
      })
      router.push(Routes.HOME)
    }
  }, [canRedirect, errorCode, router, session.data?.whoAmI, session.status])

  useSigninEffect(canRedirect, disableSigninCallbackUrl)

  if (session.status !== 'loading' && !canRedirect) {
    return (
      <div>
        <Head>
          <title>{t('heading')}</title>
        </Head>
        <h1>{t('heading')}</h1>
        {errorCode && <p>{t('error')}</p>}
        <Link
          href={Routes.SIGNIN}
          className="usa-button"
          data-testid="page-signin-link"
        >
          {t('signin_button')}
        </Link>
      </div>
    )
  }

  return <PageLoader />
}

export default SignIn
